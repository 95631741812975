import axios from 'axios';
import type { TokenToUSDT } from './wallet.service.types';

export const walletService = {
  getCoinsToUSDTConversion: (coins: string) => {
    return axios.get<TokenToUSDT[]>(
      `https://api.binance.com/api/v3/ticker/price?symbols=${coins}`,
    );
  },
};
