export function formatTimestamp(timestamp: string | number | Date) {
  const date = new Date(timestamp);
  const formattedDate = new Intl.DateTimeFormat('es-ES', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
  return formattedDate;
}

export const formatDate = (date: Date | string, header = false) => {
  const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const newDate = new Date(date);

  const year = newDate.getFullYear().toString();
  const month = `0${(newDate.getMonth() + 1).toString()}`.slice(-2);
  const day = `0${newDate.getDate().toString()}`.slice(-2);
  const hours = `0${newDate.getHours().toString()}`.slice(-2);
  const minutes = `0${newDate.getMinutes().toString()}`.slice(-2);

  const monthName = MONTHS[Number(month) - 1];

  if (!header) return `${year}-${month}-${day} ${hours}:${minutes}`;
  else return `${monthName} ${day}`;
};

export function formatPrice(price: number) {
  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  });
  return numberFormat.format(price / 100);
}

export function truncateText(text: string, count: number) {
  const words = text.split(/\s+/);
  return words.slice(0, count).join(' ') + (words.length > count ? ' ...' : '');
}

export function truncateWord(word: string, count: number) {
  if (word.length > count) {
    return `${word.substring(0, count)}...`;
  } else {
    return word;
  }
}

export function truncateHash(word: string, count: number, until: number) {
  if (word.length > count) {
    return `${word.substring(0, count)}...${word.slice(-until)}`;
  } else {
    return word;
  }
}
