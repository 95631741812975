<script lang="ts" setup>
import GenericModal from '@/components/modals/GenericModal.vue';
import LoginModalForm from '@/components/modals/auth/LoginModalForm.vue';
import RecoveryModalForm from '@/components/modals/auth/RecoveryModalForm.vue';
import { useAuthStore } from '@/stores';
import { computed, onMounted } from 'vue';
import { ref } from 'vue';
import { useWalletStore } from '@/stores/wallet';

const currentView = ref<'login' | 'register' | 'recovery'>('login');

const { chainEndpoint } = useWalletStore();
interface Props {
  modelValue: boolean;
  message?: string;
}

const props = withDefaults(defineProps<Partial<Props>>(), {});

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
  'on:close': [];
}>();

const show = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

function handleViewChange(view: 'login' | 'register' | 'recovery') {
  currentView.value = view;
}

const modalTitle = computed(() => {
  switch (currentView.value) {
    case 'recovery':
      return 'Password recovery';
    default:
      return '';
  }
});

const authStore = useAuthStore();
onMounted(() => {
  authStore.sequenceInit(chainEndpoint);
});
</script>

<template>
  <GenericModal
    v-model="show"
    :title="modalTitle"
    size="md"
    @on:close="emit('on:close')"
  >
    <template #default>
      <div data-testid="login-modal" class="px-6">
        <template v-if="currentView === 'login'">
          <p class="mb-4 text-center text-lg">
            {{ message }}
          </p>
          <LoginModalForm
            @change:view="handleViewChange"
            @close:modal="show = false"
          />
        </template>
        <template v-if="currentView === 'recovery'">
          <RecoveryModalForm @change:view="handleViewChange" />
        </template>
      </div>
    </template>
  </GenericModal>
</template>
