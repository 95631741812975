<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.65377 0.90108C3.9552 1.49033 2.49037 2.60874 1.47441 4.09204C0.45846 5.57533 -0.0550553 7.34533 0.00929359 9.14205C0.0736425 10.9388 0.712464 12.6675 1.83193 14.0743C2.95139 15.4811 4.49248 16.4919 6.22885 16.9581C7.63656 17.3213 9.11142 17.3373 10.5267 17.0046C11.8087 16.7166 12.994 16.1006 13.9665 15.2169C14.9786 14.2691 15.7133 13.0634 16.0915 11.7293C16.5024 10.2785 16.5756 8.75287 16.3053 7.26944H8.40033V10.5486H12.9784C12.8869 11.0716 12.6908 11.5707 12.4019 12.0162C12.113 12.4617 11.7372 12.8442 11.297 13.1411C10.738 13.511 10.1078 13.7598 9.44689 13.8715C8.78409 13.9948 8.10423 13.9948 7.44142 13.8715C6.7696 13.7328 6.13407 13.4555 5.57541 13.0574C4.67779 12.422 4.00381 11.5193 3.64963 10.4782C3.28956 9.41752 3.28956 8.26768 3.64963 7.20702C3.90174 6.46355 4.31852 5.78663 4.86885 5.22678C5.49863 4.57434 6.29596 4.10797 7.17335 3.87884C8.05074 3.64971 8.97429 3.66668 9.84267 3.92788C10.5211 4.13602 11.1414 4.49987 11.6542 4.99038C12.1704 4.47683 12.6857 3.96197 13.2002 3.44577C13.4658 3.16819 13.7553 2.90389 14.017 2.61967C13.2341 1.89122 12.3152 1.32436 11.3129 0.951549C9.48767 0.288801 7.49054 0.27099 5.65377 0.90108Z"
      fill="white"
    />
    <path
      d="M5.65516 0.901237C7.49177 0.270719 9.48891 0.288061 11.3143 0.950378C12.3168 1.32572 13.2352 1.89531 14.017 2.62647C13.7514 2.91069 13.4712 3.17632 13.2002 3.45257C12.6849 3.96699 12.1701 4.47965 11.6556 4.99053C11.1428 4.50003 10.5225 4.13618 9.84406 3.92803C8.97597 3.66592 8.05245 3.64797 7.17482 3.87616C6.2972 4.10435 5.49938 4.56987 4.86891 5.22163C4.31858 5.78148 3.9018 6.4584 3.64969 7.20186L0.896484 5.07022C1.88196 3.11597 3.58826 1.62111 5.65516 0.901237Z"
      fill="#E33629"
    />
    <path
      d="M0.164684 7.18179C0.312557 6.44836 0.558241 5.73811 0.895153 5.07007L3.64836 7.20702C3.28829 8.26769 3.28829 9.41753 3.64836 10.4782C2.73106 11.1865 1.81333 11.8984 0.895153 12.6138C0.0519932 10.9355 -0.205156 9.02324 0.164684 7.18179Z"
      fill="#F8BD00"
    />
    <path
      d="M8.40098 7.26831H16.306C16.5762 8.75175 16.5031 10.2774 16.0922 11.7282C15.714 13.0622 14.9793 14.268 13.9672 15.2158C13.0786 14.5225 12.1861 13.8346 11.2976 13.1413C11.7381 12.8442 12.1141 12.4611 12.403 12.0152C12.692 11.5693 12.8879 11.0696 12.979 10.5461H8.40098C8.39965 9.4544 8.40098 8.36136 8.40098 7.26831Z"
      fill="#587DBD"
    />
    <path
      d="M0.894531 12.6139C1.81271 11.9056 2.73044 11.1937 3.64773 10.4783C4.00262 11.5198 4.67757 12.4225 5.57617 13.0575C6.13657 13.4538 6.77347 13.7288 7.44617 13.865C8.10898 13.9883 8.78883 13.9883 9.45164 13.865C10.1126 13.7533 10.7428 13.5045 11.3017 13.1345C12.1902 13.8278 13.0827 14.5158 13.9712 15.2091C12.9989 16.0932 11.8136 16.7097 10.5314 16.998C9.11617 17.3307 7.6413 17.3148 6.23359 16.9516C5.12023 16.6543 4.08027 16.1302 3.17891 15.4123C2.22495 14.6547 1.44575 13.7002 0.894531 12.6139Z"
      fill="#319F43"
    />
  </svg>
</template>
