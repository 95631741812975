import axios from 'axios';
import { type Ranking, type RankingPopulated } from './rankings.service.types';

const rankingBack = axios.create({
  baseURL: import.meta.env.VITE_API_RANKING_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const rankingService = {
  async getAllRankings() {
    return await rankingBack.get<Ranking[]>('/rankings');
  },

  async getRankingById(id: string) {
    return await rankingBack.get<RankingPopulated>(`/rankings/${id}`);
  },

  async sendScore(score: number, hash_key: string) {
    return await rankingBack.post<{ message: string }>(
      '/points/request-token',
      {
        score,
        hash_key,
      },
    );
  },

  async confirmScore(score: number, hash_key: string, token: string) {
    return await rankingBack.post<{ message: string }>(
      '/points/request-token/confirm',
      {
        score,
        hash_key,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  async checkWalletAddress(wallet_address: string) {
    return await rankingBack.put('/user/check-wallet', {
      wallet_address,
    });
  },
};
