export const popupCenter = (
  url: string,
  title: string,
  w: number,
  h: number,
) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const l = window.screenLeft as number | undefined;
  const dualScreenLeft = l !== undefined ? window.screenLeft : window.screenX;
  const t = window.screenTop as number | undefined;
  const dualScreenTop = t !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${String(w / systemZoom)}, 
      height=${String(h / systemZoom)}, 
      top=${String(top)}, 
      left=${String(left)}
      `,
  );

  newWindow?.focus();
  return newWindow;
};

export const setRouteToGo = (route: string) => {
  //Save in session storage the route to go after login
  sessionStorage.setItem('routeToGo', route);
};

export const getRouteToGo = () => {
  //Get the route to go after login
  const routeToGo = sessionStorage.getItem('routeToGo');
  sessionStorage.removeItem('routeToGo');
  return routeToGo;
};

export const getQueryParams = (fullPath?: string | null) => {
  const params: Record<string, string> = {};
  if (!fullPath) return params;
  try {
    const query = fullPath.split('?')[1];
    if (!query) return params;
    const paramsStr = query.split('&');
    paramsStr.forEach((param) => {
      const [key, value] = param.split('=');
      params[key] = value;
    });
    return params;
  } catch (error) {
    return params;
  }
};
