<script setup lang="ts">
import { computed } from 'vue';
import GenericModal from '../modals/GenericModal.vue';
import ButtonComponent from '../common/ButtonComponent.vue';

interface Props {
  modelValue: boolean;
  message: string;
  title: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const show = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <GenericModal
    v-model="show"
    size="sm"
    container-class="bg-backgrounds-darkestgrey"
    :title="title"
  >
    <div data-testid="cando-modal" class="w-full gap-3 pt-5">
      <p>{{ message }}</p>

      <div class="mt-5 flex w-full flex-col items-end">
        <ButtonComponent width="sm" @click="show = false">
          Close
        </ButtonComponent>
      </div>
    </div>
  </GenericModal>
</template>

<style scoped></style>
