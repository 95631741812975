import { type Coins } from '@/composables/useWallet';
import {
  ContractType,
  type TxnTransfer,
  type Transaction,
} from '@0xsequence/indexer';

export function transactionValidate(
  transaction: Transaction,
  coins: Coins,
): boolean {
  if (!transaction.blockHash || !transaction.transfers?.length) {
    return false;
  }

  const transfer: TxnTransfer = transaction.transfers[0];

  if (transfer.contractType === ContractType.NATIVE) {
    return true;
  }

  if (transfer.contractInfo?.name.toLowerCase().includes('http')) {
    return false;
  }

  if (
    transfer.contractType === ContractType.ERC20 &&
    transfer.contractInfo?.symbol
  ) {
    return Boolean(coins[transfer.contractInfo.symbol]);
  }

  return true;
}
