import axios from 'axios';
import type { LegendRoom, SuggestedRoom } from './room-creator.service.types';

const backendUrl = import.meta.env.VITE_ROOM_CREATOR_URL;

const commonHeaders = {
  'Content-Type': 'application/json',
};

const roomCreatorApi = axios.create({
  baseURL: backendUrl,
  withCredentials: true,
  headers: { ...commonHeaders },
});

export const roomCreatorService = {
  async getByName(roomName: string) {
    return await roomCreatorApi.get<LegendRoom>(
      `room/getByName?name=${roomName}`,
    );
  },
  getRoomsMinified() {
    return roomCreatorApi.get<LegendRoom[]>('/room/');
  },
  getRoomById(id: string) {
    return roomCreatorApi.get<LegendRoom>(`/room/${id}`);
  },
  getRoomTypes() {
    return roomCreatorApi.get<string[]>('/room/types');
  },
  createRoom(room: LegendRoom) {
    return roomCreatorApi.post<LegendRoom>('/room/', room);
  },
  deleteRoom(id: string) {
    return roomCreatorApi.delete(`/room/${id}`);
  },

  saveContactForm(
    fullName: string,
    activity: string,
    email: string,
    phoneNumber: string,
    comment: string,
  ) {
    return roomCreatorApi.post('/contactForm', {
      fullName: fullName,
      activity: activity,
      email: email,
      phoneNumber: phoneNumber,
      comment: comment,
    });
  },
  getSuggestedRoooms() {
    return roomCreatorApi.get<SuggestedRoom[]>('/room/getOnlyFavs');
  },
};
