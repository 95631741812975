import type {
  Category,
  Collection,
  UserRoom,
  UserRoomProductType,
  View,
  VirtualProduct,
  VirtualProductInteraction,
  VisualCategory,
} from '@/services/showcase.service.types';
import axios from 'axios';

const showcaseApi = axios.create({
  baseURL: import.meta.env.VITE_API_SHOWCASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const showcaseService = {
  async getViews() {
    return await showcaseApi.get<View[]>(`/public/view`);
  },
  async getViewById(id: string) {
    return await showcaseApi.get<View>(`/public/view/${id}`);
  },
  async getViewBySlug(slug: string) {
    return await showcaseApi.get<View>(`/public/view/findBySlug/${slug}`);
  },
  async getCollectionBySlug(slug: string) {
    return await showcaseApi.get<Collection>(
      `/public/collection/findBySlug/${slug}`,
    );
  },
  async getCollections() {
    return await showcaseApi.get<Collection[]>(`/public/collection`);
  },
  async getCategoryById(id: string) {
    return await showcaseApi.get<Category>(`/public/category/${id}`);
  },
  async getCategoryBySlug(slug: string) {
    return await showcaseApi.get<Category>(
      `/public/category/findBySlug/${slug}`,
    );
  },
  async getCategories() {
    return await showcaseApi.get<Category[]>(`/public/category`);
  },
  async getVisualCategoryBySlug(slug: string) {
    return await showcaseApi.get<VisualCategory>(
      `/public/visual-category/findBySlug/${slug}`,
    );
  },
  async getProductsByVisualCategories(categories_ids: string[]) {
    return await showcaseApi.post<VisualCategory[]>(
      '/public/products/findByVisualCategories',
      { categories_ids },
    );
  },
  async getProductsByCategory(category_id: string) {
    return await showcaseApi.post<VirtualProduct[]>(
      '/public/products/findByCategory',
      {
        category_id,
      },
    );
  },
  async getProductsByCollection(colletionId: string) {
    return await showcaseApi.get<VirtualProduct[]>(
      `/public/products/findByCollection/${colletionId}`,
    );
  },
  async getProductById(id: string) {
    return await showcaseApi.get<VirtualProduct>(`/products/${id}`);
  },
  async getProductsById(ids: string[]) {
    return await showcaseApi.post<VirtualProduct[]>(
      '/products/findByProductIds',
      {
        product_ids: ids,
      },
    );
  },
  async getProductBySlug(slug: string) {
    return await showcaseApi.get<VirtualProduct>(
      `/products/findBySlug/${slug}`,
    );
  },
  async getProductByRoomName(name: string) {
    return await showcaseApi.get<VirtualProduct>(
      `/products/findByRoomName/${name}`,
    );
  },
  // Desenchufado, habilitar en el backend
  async getProductsSearchByPrefix(prefix: string) {
    return await showcaseApi.get<{ word: string; value: VirtualProduct }[]>(
      `/public/products/prefixSearch?prefix=${prefix}`,
    );
  },
  async addVisit(data: { productVirtualId: string }) {
    return await showcaseApi.post<VirtualProductInteraction>(
      `/products/interactions/addVisit`,
      data,
    );
  },
  async updateProductInteractions(data: {
    productVirtualId: string;
    favorite?: boolean;
    like?: boolean;
  }) {
    return await showcaseApi.post<VirtualProductInteraction>(
      `/products/interactions`,
      data,
    );
  },
  async getMyFavorites() {
    return await showcaseApi.get<VirtualProduct[]>(`/products/findFavorites`);
  },
  async getMyRooms() {
    return await showcaseApi.get<UserRoom[]>(`/products/rooms/user`);
  },
  async getUserRooms(userId: string) {
    return await showcaseApi.get<UserRoom[]>(`/products/rooms/user/${userId}`);
  },
  async createMyRoom(type: UserRoomProductType, templateId?: string) {
    return await showcaseApi.post<VirtualProduct[]>(`/products/rooms/user`, {
      type,
      templateId,
    });
  },
};
