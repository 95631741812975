<script setup lang="ts">
import { ref } from 'vue';
import AuthInput from '@/components/auth/AuthInput.vue';
import { authService } from '@/services';
import EmailInput from '@/components/auth/EmailInput.vue';
import { handleAxiosError } from '@/utils/error';

const email = ref('');
const emailError = ref(false);
const emailErrorMsg = ref('');
const codeError = ref(false);
const codeErrorMsg = ref('');
const codeSent = ref(false);
const code = ref('');
const userEmail = ref('');
const codeAlreadyInUse = ref(false);

interface TokenFlow {
  trueToken: true;
  email: string;
  code: string;
}

const emits = defineEmits<{
  update: [trueToken: TokenFlow];
  back: [void];
}>();

const clearEmailErrors = () => {
  emailError.value = false;
  emailErrorMsg.value = '';
};

const clearTokenErrors = () => {
  codeError.value = false;
  codeErrorMsg.value = '';
};

const sendCode = async () => {
  try {
    userEmail.value = email.value;
    await authService.sendCodeToRestorePassword(email.value);
    codeSent.value = true;
  } catch (error) {
    const { message } = handleAxiosError(error);
    if (message.toLowerCase().includes('use existing access code')) {
      // tiene un código activo que debe utilizar antes de solicitar uno nuevo
      codeSent.value = true;
      codeAlreadyInUse.value = true;
    } else {
      // error en el email
      emailError.value = true;
      emailErrorMsg.value = message;
    }
  }
};

const emitSentCode = () => {
  const emitData: TokenFlow = {
    trueToken: true,
    email: userEmail.value,
    code: code.value,
  };
  emits('update', emitData);
};

const handleEmail = (modelValue: string) => {
  email.value = modelValue;
};

const handleCode = (tokenCode: string) => {
  code.value = tokenCode;
};

const validateCodeField = () => {
  let error = false;

  const codeRegex = /^\d{1,4}$/;

  if (!codeRegex.test(code.value)) {
    codeError.value = true;
    codeErrorMsg.value = /^[0-9]+$/.test(code.value)
      ? 'Code must contain a maximum of 4 digits.'
      : 'Code must be numeric.';
    error = true;
  }

  return error;
};

const validateCode = async () => {
  if (!code.value) return;
  if (validateCodeField()) return;
  try {
    await authService.validateCodeToRestorePassword(
      userEmail.value,
      code.value,
    );
    codeSent.value = false;
    emitSentCode();
  } catch (error) {
    const { message } = handleAxiosError(error);
    codeError.value = true;
    codeErrorMsg.value = message;
  }
};
</script>

<template>
  <div class="">
    <div class="flex flex-col">
      <form @submit.prevent="codeSent ? validateCode() : sendCode()">
        <div v-if="!codeSent">
          Enter your email to recieve the recovery code
        </div>
        <div v-if="!codeSent" class="relative mt-4 flex w-full flex-col">
          <EmailInput
            v-model="email"
            :error="emailError"
            :title="`Email`"
            name="email"
            :error-message="emailErrorMsg"
            @clear-errors="clearEmailErrors"
            @update="handleEmail"
          />
        </div>
        <div v-if="codeSent" class="flex flex-col">
          <div v-if="codeAlreadyInUse">
            Token was already sent to <strong>{{ email }}</strong>
          </div>
          <div v-if="codeAlreadyInUse" class="mt-2">
            Please enter the token provided in the previous email.
          </div>
          <div v-else>
            Verify your email <strong>{{ email }}</strong> and enter the code
            provided
          </div>
          <div class="relative mt-4 flex w-full flex-col">
            <AuthInput
              v-model="code"
              :error="codeError"
              :title="`Code`"
              :error-message="codeErrorMsg"
              :input-type="`text`"
              :placeholder="`Enter the 4 digit code`"
              @clear-errors="clearTokenErrors"
              @update="handleCode"
            />
          </div>
        </div>
        <div class="mt-6 flex flex-row items-center justify-center gap-5">
          <ButtonComponent
            width="sm"
            type="button"
            variant="ghost"
            @click="emits('back')"
          >
            Back
          </ButtonComponent>
          <ButtonComponent v-if="!codeSent" width="sm" type="submit">
            Send code
          </ButtonComponent>
          <ButtonComponent
            v-if="codeSent"
            type="submit"
            width="sm"
            @click="validateCode"
          >
            Continue
          </ButtonComponent>
        </div>
      </form>
    </div>
  </div>
</template>
