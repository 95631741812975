<script setup lang="ts">
import GoogleLogo from '@/components/icons/GoogleLogo.vue';
import EmailLogin from '@/components/modals/auth/EmailLogin.vue';
import MicrosoftLogo from '@/components/icons/MicrosoftLogo.vue';
import UPMLogo from '@/components/icons/UPMLogo.vue';
import { useAuthStore } from '@/stores';
import { computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getQueryParams, getRouteToGo } from '@/utils/popup';
import { AuthRequest } from '@/services';
import ButtonComponent from '@/components/common/ButtonComponent.vue';
import { handleAxiosError } from '@/utils/error.ts';

const authStore = useAuthStore();
const router = useRouter();

const isLoading = ref(false);
const loginData = {} as AuthRequest;
const emailError = ref(false);
// TODO: remove comment when email login is added again, requested by https://legendaryum.atlassian.net/browse/LE-1161
const emailErrorMessage = ref('');
const credentialsError = ref(false);
const credentialsErrorMessage = ref('');
const microsoftAuthUrl = ref('');
const upmAuthUrl = ref('');
const emailLogin = ref(false);

const allowEmailLogin = computed(() => import.meta.env.VITE_ENV === 'dev');

const emit = defineEmits<{
  'change:view': [value: 'login' | 'register' | 'recovery'];
  'close:modal': [];
}>();

const allowMicrosoftSSO = computed(
  () =>
    import.meta.env.VITE_ENV === 'dev' ||
    import.meta.env.VITE_ENV === 'staging',
);

onMounted(async () => {
  try {
    const { microsoft, upm } = await authStore.getAuthUrl();
    microsoftAuthUrl.value = microsoft;
    upmAuthUrl.value = upm;
  } catch (error) {
    handleAxiosError(error, true);
  }
});

const microsoftAuth = () => {
  window.location.href = microsoftAuthUrl.value;
};

const upmAuth = () => {
  window.location.href = upmAuthUrl.value;
};

const googleAuth = async () => {
  isLoading.value = true;
  if (!authStore.nonce) await authStore.sequenceHash();

  // Crear la URL de autenticación de Google con el nonce en la query
  const googleAuthUrl = new URL('https://accounts.google.com/o/oauth2/v2/auth');
  googleAuthUrl.searchParams.set(
    'client_id',
    (await authStore.getAuthUrl()).google,
  );
  googleAuthUrl.searchParams.set('scope', 'email profile openid');
  googleAuthUrl.searchParams.set('response_type', 'code');
  googleAuthUrl.searchParams.set('redirect_uri', window.location.origin);
  googleAuthUrl.searchParams.set('nonce', authStore.nonce);

  // Abrir la URL de autenticación de Google en una ventana emergente
  const width = 500;
  const height = 600;
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;

  const popup = window.open(
    googleAuthUrl.toString(),
    'googleAuth',
    `width=${width},height=${height},top=${top},left=${left}`,
  );

  // Monitorear la ventana emergente
  const pollPopup = setInterval(async () => {
    try {
      // Intentar acceder a la URL actual de la ventana emergente
      if (popup?.location.href) {
        const currentUrl = new URL(popup.location.href);

        // Verificar si hay un código de autenticación en la URL
        if (currentUrl.searchParams.has('code')) {
          const authCode = currentUrl.searchParams.get('code');

          // Cerrar la ventana emergente
          popup.close();
          clearInterval(pollPopup);
          isLoading.value = false;

          // Manejar el código de autenticación
          await handleAuthCode(authCode);
        }
      }
    } catch (error) {
      if (!popup || popup.closed) {
        clearInterval(pollPopup);
        isLoading.value = false;
        console.error('La ventana emergente se cerró sin autenticación.');
      }
    }
    const path = getRouteToGo();
    await router.push({
      path: path ?? '/',
      query: { ...getQueryParams(path) },
    });
  }, 500);
};

const handleAuthCode = async (authCode: string | null) => {
  if (authCode) {
    try {
      await authStore.login('', '', 'google', authCode);
      emit('close:modal');
    } catch (error) {
      handleAxiosError(error);
    }
  } else {
    console.error('No se recibió un código de autenticación.');
  }
};

const redirectEmailLogin = () => {
  if (emailError.value) return;
  emailLogin.value = true;
};
</script>

<template>
  <EmailLogin
    v-if="emailLogin"
    :email="loginData.email"
    @back="emailLogin = false"
    @recovery="emit('change:view', 'recovery')"
    @on:close="emit('close:modal')"
  />
  <form v-else @submit.prevent="redirectEmailLogin">
    <div class="grid grid-cols-1">
      <div class="flex flex-col">
        <div class="grid grid-cols-1">
          <div class="flex flex-col justify-center">
            <div class="mb-4 flex flex-col items-center justify-center">
              <img class="w-full max-w-48" src="/images/Logo_vertical.svg" />
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1">
          <div class="flex flex-col">
            <p class="text-md text-center">Choose your account to log in</p>
            <div
              v-if="allowEmailLogin"
              class="relative my-3 mb-5 flex flex-col"
            >
              <label for="email" class="mb-1 block text-sm">Email</label>
              <input
                v-model="loginData.email"
                name="email"
                type="email"
                class="h-9 w-full rounded-md border-2 p-4"
                :class="{
                  'border-2 bg-white text-black': !emailError,
                  'border-2 border-red-500 bg-white text-red-700': emailError,
                }"
                placeholder="email@email.com"
                required
                maxlength="50"
              />
              <p
                v-if="emailError"
                class="absolute right-0 top-16 text-right text-xs italic"
              >
                {{ emailErrorMessage }}
              </p>
              <div class="flex items-center justify-center">
                <ButtonComponent type="submit" class="mt-6">
                  Continue
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-6 flex flex-col items-center justify-center gap-4">
          <button
            type="button"
            class="flex w-full cursor-pointer items-center justify-center gap-4 rounded-lg bg-white p-2 text-black transition-all hover:bg-white/85"
            @click="googleAuth"
          >
            <GoogleLogo />
            <div>
              <span class="select-none">Login with Google</span>
            </div>
          </button>
          <button
            v-if="allowMicrosoftSSO"
            type="button"
            class="flex w-full cursor-pointer items-center justify-center rounded-lg bg-white p-2 text-black transition-all hover:bg-white/85"
            @click="microsoftAuth"
          >
            <div class="ml-4 flex gap-4">
              <MicrosoftLogo class="size-1" />
              <div>
                <span class="select-none">Login with Microsoft</span>
              </div>
            </div>
          </button>
          <button
            type="button"
            class="flex w-full cursor-pointer items-center justify-center rounded-lg bg-white p-2 text-black transition-all hover:bg-white/85"
            @click="upmAuth"
          >
            <div class="mr-6 flex gap-4">
              <UPMLogo class="size-6" />
              <div>
                <span class="select-none">Login with UPM</span>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div class="mt-6 flex items-center justify-center">
        <div
          v-if="credentialsError"
          class="w-full rounded-md bg-[#50191d] px-3 py-1 text-center text-[#f88f96]"
        >
          <p class="text-center">
            {{ credentialsErrorMessage }}
          </p>
        </div>
      </div>
    </div>
  </form>
</template>

<style scoped></style>
