import axios from 'axios';

const backendUrl = import.meta.env.VITE_LEGEND_TRANSACTIONAL;

const commonHeaders = {
  'Content-Type': 'application/json',
};

const transactionalApi = axios.create({
  baseURL: backendUrl,
  withCredentials: true,
  headers: { ...commonHeaders },
});

export const transactionalService = {
  changeTemplateId(roomId: string, templateId: string) {
    return transactionalApi.post('/saga/changeTemplateId?timeout=10000', {
      roomId,
      templateId,
    });
  },
  updateSocialUserImage(picture: string) {
    return transactionalApi.put<null>(
      '/saga/updateSocialUserImage?timeout=10000', // 10s
      {
        picture,
      },
    );
  },
};
