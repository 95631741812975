<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue';
import { computed } from 'vue';

interface Props {
  modelValue: boolean;
  title?: string;
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | '8xl'
    | '9xl';
  containerClass?: string;
  contentClass?: string;
  footerClass?: string;
  bgClass?: string;
  overlay?: boolean;
  screenPosition?: string;
  hiddenTitle?: boolean;
  closeToClickOutside?: boolean;
  titleClass?: string;
  titleClassContainer?: string;
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  size: 'md',
  containerClass: '',
  bgClass: 'bg-darkgrey',
  contentClass: '',
  footerClass: '',
  overlay: true,
  screenPosition: 'inset-0',
  hiddenTitle: false,
  closeToClickOutside: true,
  titleClass: '',
  titleClassContainer: '',
});

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
  'on:close': [];
}>();

const slots = defineSlots<{
  default(): void;
  footer(): void;
}>();

const show = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

function closeModalToClickOutside() {
  if (!props.closeToClickOutside) return;
  show.value = false;
  emit('on:close');
}

function closeModal() {
  show.value = false;
  emit('on:close');
}
const bgClass = computed(() => `${props.bgClass}`);
const maxWidthClass = computed(() => `max-w-${props.size}`);
const containerClass = computed(
  () => `${bgClass.value} ${maxWidthClass.value} ${props.containerClass}`,
);
</script>

<template>
  <TransitionRoot
    appear
    :show="show"
    as="template"
    @after-leave="emit('on:close')"
  >
    <Dialog
      :unmount="true"
      as="div"
      class="relative z-[900000]"
      @close="closeModalToClickOutside"
    >
      <TransitionChild
        v-if="overlay"
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/75"></div>
      </TransitionChild>

      <div class="fixed overflow-y-auto" :class="screenPosition">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full overflow-hidden rounded-2xl text-left align-middle shadow-xl transition-all"
              :class="containerClass"
            >
              <DialogTitle
                v-if="!hiddenTitle"
                as="h3"
                class="relative flex items-center gap-2 border-b-white p-4 pb-2 text-lg font-medium leading-6 text-white"
                :class="titleClassContainer"
              >
                <p :class="titleClass">
                  {{ title }}
                </p>
                <button
                  class="absolute right-4 cursor-pointer outline-0"
                  @click="closeModal"
                >
                  <IconifyIcon icon="material-symbols:close" />
                </button>
              </DialogTitle>

              <div :class="contentClass" class="mb-4 px-4">
                <slot name="default" />
              </div>

              <div v-if="!!slots.footer" :class="footerClass" class="px-4 pb-4">
                <slot name="footer" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
