import axios from 'axios';
import type {
  CancelSubscriptionPayload,
  CreateSessionPayload,
  ProductsResponse,
  ValidResponse,
} from './payments.service.types';

const paymentsApi = axios.create({
  baseURL: import.meta.env.VITE_API_COINS_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const paymentsService = {
  createSession: async (p: CreateSessionPayload) => {
    return await paymentsApi.post<ValidResponse>(`/stripe/create-session`, {
      ...p,
    });
  },
  cancelSubscription: async (p: CancelSubscriptionPayload) => {
    return await paymentsApi.post<{ billingPortalSessionUrl: string }>(
      `/stripe/cancel-subscription`,
      { ...p },
    );
  },
  stopPendingCancellation: async () => {
    return await paymentsApi.put<null>(`/stripe/stop-pending-cancellation`);
  },
  publishableKey: async () => {
    return await paymentsApi.get<{
      publishableKey: string;
    }>(`/public/stripe/config`);
  },
  products: async () => {
    return await paymentsApi.post<ProductsResponse>(`/products`);
  },
  coins: async () => {
    return await paymentsApi.get<{ coins: number }>(`/coins`);
  },
};
