<script setup lang="ts">
import useProfile from '@/composables/useProfile';
import ButtonComponent from '../ButtonComponent.vue';

const { hasUser } = useProfile();
</script>

<template>
  <router-link v-if="hasUser" :to="{ name: 'MissionsView' }">
    <ButtonComponent variant="earn" class="">Missions to earn</ButtonComponent>
  </router-link>
</template>

<style scoped></style>
