import type { LegendRoom } from '@/services';

export const userRoomProductType = {
  // rooms del usuario
  RoomIsland: 'room:island',
  RoomHallOfFame: 'room:hallOfFame',
  RoomHouse: 'room:house',
} as const;

export type UserRoomProductType =
  (typeof userRoomProductType)[keyof typeof userRoomProductType];

export const productType = {
  ...userRoomProductType,
  RoomNormal: 'room:normal',
  Experience: 'EXPERIENCE',
  Event: 'EVENT',
} as const;

export type ProductType = (typeof productType)[keyof typeof productType];

export type UserRoom = Record<UserRoomProductType, VirtualProduct>;

type ImageUrl = string;

export interface ProductMetadata {
  like?: boolean;
  favorite?: boolean;
  likes?: number;
  favorites: number;
  visits?: number; // vistas unicas
  visitsCount?: number; // vistas totales
}

export interface Category {
  _id: string;
  name: string;
  position: number;
  slug: string;
  products?: VirtualProduct;
  image: ImageUrl;
}
export interface VirtualProduct {
  owner: string;
  _id: string;
  name: string;
  description: string;
  images: ImageUrl[];
  visualCategory: VisualCategory[];
  category: Category[];
  productRefId: string;
  slug: string;
  public: boolean;
  productType: ProductType;
  product?: LegendRoom;
  productMetadata?: ProductMetadata;
}

export interface VisualCategory {
  _id: string;
  name: string;
  position: number;
  image: ImageUrl;
  slug: string;
  products?: VirtualProduct[];
  collections?: Collection[];
}

export interface Collection {
  _id: string;
  name: string;
  image: string;
  video: string;
  slug: string;
  categories: Category[];
  slider: Slide[];
  visualCategories: string[];
}

export enum BlockType {
  Banner = 'banner',
  CallToAction = 'call-to-action',
  VisualCategory = 'visual-category',
}

export interface Block {
  type: BlockType;
  block: VisualCategory | Banner | CallToAction;
  products?: VirtualProduct[];
  max?: number;
}

export interface View {
  _id: string;
  name: string;
  slug: string;
  collections: Collection[];
  slider: Slide[];
  blocks: Block[];
}

export interface VirtualProductInteraction {
  _id: string;
  productVirtualId: string[];
  userId: string[];
  createdAt: Date;
  favorite: boolean;
  like: boolean;
  updatedAt: Date;
}

export interface Slide {
  _id: string;
  name: string;
  title: string;
  subtitle: string;
  description: string;
  buttonText: string;
  link: string;
  images: string[];
  videos: string[];
  imgMobile: string;
}

export interface Banner {
  _id: string;
  name: string;
  link: string;
  images: string[];
  videos: string[];
  buttonText: string;
  imgMobile: string;
}

export interface CallToAction {
  _id: string;
  name: string;
  title: string;
  subtitle: string;
  description: string;
  link: string;
  images: string[];
  videos: string[];
  buttonText: string;
  fullWidth: boolean;
  imgMobile: string;
  titleImage: string;
  contentBackground: string;
}
