import { onMounted, onUnmounted, watch } from 'vue';
import { ethers } from 'ethers';
import { useWalletStore } from '@/stores/wallet';

const providers =
  import.meta.env.VITE_ENV === 'prod'
    ? ({
        MATIC: 'https://polygon.llamarpc.com',
        BNB: 'https://binance.llamarpc.com',
      } as Record<string, string>)
    : ({
        MATIC: 'https://polygon-amoy.drpc.org',
        BNB: 'https://data-seed-prebsc-1-s1.bnbchain.org:8545',
      } as Record<string, string>);

export function useBalance() {
  const walletStore = useWalletStore();
  let intervalId: ReturnType<typeof setInterval> | null = null;

  async function checkBalance() {
    try {
      if (!walletStore.walletAddress || !walletStore.mainToken) return;
      const provider = new ethers.JsonRpcProvider(
        providers[walletStore.tokenName],
      );

      const balanceWei = await provider.getBalance(walletStore.walletAddress);
      if (balanceWei.toString() !== walletStore.mainToken.balance.balanceWei) {
        walletStore.transactions =
          await walletStore.client?.getTransactionHistory({
            filter: {
              accountAddress: walletStore.walletAddress,
            },
            includeMetadata: true,
          });
      }

      walletStore.mainToken.balance.balanceWei = balanceWei.toString();
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  }

  async function startBalanceCheck() {
    if (intervalId) clearInterval(intervalId);
    intervalId = setInterval(checkBalance, 10000);
    await checkBalance();
  }

  onMounted(async () => {
    await startBalanceCheck();
  });

  onUnmounted(() => {
    if (intervalId) clearInterval(intervalId);
  });

  watch(
    () => walletStore.mainToken,
    async () => {
      await startBalanceCheck();
    },
  );

  return {
    startBalanceCheck,
  };
}
