import { defineStore } from 'pinia';

export interface LoadingScreen {
  show?: boolean;
  title?: string;
  buttonText?: string;
  showProgressBar?: boolean;
  percentage?: number;
}

export interface UnityUserSettings {
  musicVolume: number;
  sfxVolume: number;
  graphicsQuality: number;
}

interface LegendState {
  currentRoomId?: string;
  currentMapName: string;
  loadingMap: string;
  loadingScreen: LoadingScreen;
  currentLayer: number | undefined;
  currentAnimationState: string | undefined;
  currentPlayersCount: number;
  isFullscreen: boolean;
  isCinemaMode: boolean;
  toggleFullscreen: boolean;
  showEditorMode: boolean;
  showChat: boolean;
  showUnityInterface: boolean;
  showUnityComponent: boolean;
  shareModal: {
    showShareModal: boolean;
  };
  canvasContainerEl: HTMLElement | null;
  showImageUploadModal: boolean;
  imageUploadPromise: {
    promise: Promise<{ imageUrl: string; id: string }> | undefined;
    resolve: ((value: { imageUrl: string; id: string }) => void) | undefined;
    reject: (() => void) | undefined;
  };
  showConfirmModal: boolean;
  confirmModalPromise: {
    promise: Promise<void> | undefined;
    resolve: (() => void) | undefined;
    reject: (() => void) | undefined;
  };
  confirmModalProps: {
    title: string | undefined;
    content: string | undefined;
    confirmButtonText?: string | undefined;
    cancelButtonText?: string | undefined;
  };
  unityUserSettings: UnityUserSettings;
  rerenderTrigger: number;
}

export const useLegendStore = defineStore('legend', {
  state: (): LegendState => ({
    currentRoomId: undefined,
    currentMapName: '',
    loadingMap: '',
    loadingScreen: {
      show: true,
      title: 'Loading...',
      buttonText: 'Enter room',
      showProgressBar: true,
      percentage: 0,
    },
    currentLayer: undefined,
    currentAnimationState: undefined,
    currentPlayersCount: 0,
    isFullscreen: false,
    isCinemaMode: false,
    toggleFullscreen: false,
    showEditorMode: false,
    showChat: false,
    showUnityInterface: true,
    showUnityComponent: true,
    shareModal: {
      showShareModal: false,
    },
    canvasContainerEl: null,
    showImageUploadModal: false,
    imageUploadPromise: {
      promise: undefined,
      resolve: undefined,
      reject: undefined,
    },
    showConfirmModal: false,
    confirmModalPromise: {
      promise: undefined,
      resolve: undefined,
      reject: undefined,
    },
    confirmModalProps: {
      title: undefined,
      content: undefined,
      confirmButtonText: undefined,
      cancelButtonText: undefined,
    },
    unityUserSettings: {
      musicVolume: 0.5,
      sfxVolume: 0.5,
      graphicsQuality: 5,
    },
    rerenderTrigger: 0,
  }),
  actions: {
    setCurrentAndLoadingMap(map: string) {
      this.setLoadingMap(map);
      this.setCurrentMap(map);
    },
    setShowUnityComponent(value: boolean) {
      this.showUnityComponent = value;
    },
    setShowEditorMode(value: boolean) {
      this.showEditorMode = value;
    },
    setLoadingScreen(props: Partial<LoadingScreen>) {
      this.loadingScreen = {
        ...this.loadingScreen,
        ...props,
      };
    },
    resetLoadingScreen() {
      this.setLoadingScreen({
        show: true,
        title: 'Loading...',
        buttonText: 'Enter room',
        showProgressBar: true,
        percentage: 0,
      });
    },
    leaveRoom() {
      this.currentMapName = '';
    },
    setCurrentLayer(layer: number) {
      this.currentLayer = layer;
    },
    setCurrentAnimationState(animationState: string) {
      this.currentAnimationState = animationState;
    },
    setLoadingMap(map: string) {
      this.loadingMap = map;
    },
    setCurrentMap(map: string) {
      this.currentMapName = map;
    },
    setLoadingPercentage(currentPercentage: number) {
      this.loadingScreen.percentage = currentPercentage;
    },
    setCurrentPlayersCount(count: number) {
      this.currentPlayersCount = count;
    },
    setUnityUserSettings(newSettings: UnityUserSettings) {
      localStorage.setItem('unityUserSettings', JSON.stringify(newSettings));
      this.unityUserSettings = newSettings;
      window.unityController.updatePlayerSettings(
        newSettings.musicVolume,
        newSettings.sfxVolume,
        newSettings.graphicsQuality,
      );
    },
    initializeUnityUserSettings() {
      const storedValue = localStorage.getItem('unityUserSettings');
      if (storedValue) {
        try {
          const unitySettings = JSON.parse(storedValue) as UnityUserSettings;
          this.unityUserSettings.musicVolume = unitySettings.musicVolume;
          this.unityUserSettings.sfxVolume = unitySettings.sfxVolume;
          this.unityUserSettings.graphicsQuality =
            unitySettings.graphicsQuality;
          window.unityController.updatePlayerSettings(
            this.unityUserSettings.musicVolume,
            this.unityUserSettings.sfxVolume,
            this.unityUserSettings.graphicsQuality,
          );
        } catch (error) {
          console.error('Error on localStorage data:', error);
        }
      }
    },
    openUploadImageModal() {
      this.showImageUploadModal = true;
    },
    uploadImageToRoom(imageData: { imageUrl: string; id: string }) {
      if (
        !this.imageUploadPromise.promise ||
        !this.imageUploadPromise.reject ||
        !this.imageUploadPromise.resolve
      )
        return;

      if (!imageData.imageUrl) this.imageUploadPromise.reject();

      this.imageUploadPromise.resolve({
        imageUrl: imageData.imageUrl,
        id: imageData.id,
      });

      this.imageUploadPromise = {
        promise: undefined,
        resolve: undefined,
        reject: undefined,
      };
    },
    openConfirmModal(props: {
      title: string;
      content: string;
      confirmButtonText?: string;
      cancelButtonText?: string;
    }) {
      this.showConfirmModal = true;
      this.confirmModalProps = {
        title: props.title,
        content: props.content,
        confirmButtonText: props.confirmButtonText ?? undefined,
        cancelButtonText: props.cancelButtonText ?? undefined,
      };
    },
  },
});
