import { type MinifiedSocialUser, type SocialUser } from '@/services';

export const getUiAvatar = <T extends MinifiedSocialUser>(userInfo: T) => {
  return `https://ui-avatars.com/api/?name=${getFullName(userInfo, '+')}`;
};
// firstname and lastname can be optional, last option is username that is
// always present (we generate it)
// --separator -> only if firstname and lastname are present
export const getFullName = (
  userInfo: SocialUser | MinifiedSocialUser,
  separator = ' ',
) => {
  if (userInfo.firstName && userInfo.lastName) {
    return `${userInfo.firstName}${separator}${userInfo.lastName}`;
  }
  return userInfo.firstName ?? userInfo.username;
};
