import axios from 'axios';
import type { VideoCollection } from './plus.service.types';

const backendUrl = import.meta.env.VITE_PLUS_URL;

const commonHeaders = {
  'Content-Type': 'application/json',
};

const plusApi = axios.create({
  baseURL: backendUrl,
  withCredentials: true,
  headers: { ...commonHeaders },
});

export const plusService = {
  getVideoCollectionByName(name: string) {
    return plusApi.post<{
      videoCollection: VideoCollection;
      maxDuration: number;
    }>(`videoCollection/getByName`, { name });
  },
};
