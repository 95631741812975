import { useDirectMessageStore } from '@/stores/directMessage';
import { computed, onMounted, ref } from 'vue';
import { useSocialStore } from '@/stores';
import { type ChatMessageRecord } from '@/services';

const useDirectMessages = () => {
  const socialStore = useSocialStore();
  const directMessageStore = useDirectMessageStore();
  const isLoading = ref(false);

  onMounted(async () => {
    isLoading.value = true;
    await directMessageStore.getChats();
    isLoading.value = false;
  });

  async function markChatsAsSeen() {
    await directMessageStore.markChatsAsSeen();
  }

  function getChatUser(chat: ChatMessageRecord) {
    return directMessageStore.getChatUser(chat);
  }

  return {
    //data
    chats: computed(() => directMessageStore.chats),
    isLoading,
    hasUnseenChats: computed(() =>
      directMessageStore.chats.find(
        (chat) =>
          chat.seen === false &&
          chat.messages.at(-1)?.userId !== socialStore._socialUser?._id,
      ),
    ),
    //methods
    markChatsAsSeen,
    getChatUser,
  };
};

export default useDirectMessages;
