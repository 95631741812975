import { usePaymentsStore } from '@/stores/payments';
import { type RouteLocationNormalized } from 'vue-router';
import { toast } from './toast';
import router from '@/router';

export async function checkoutReturnHandler(to: RouteLocationNormalized) {
  const paymentsStore = usePaymentsStore();

  if (!to.query.checkout || !to.query.status) {
    return;
  }

  const status = to.query.status;

  const subscription = paymentsStore.subscriptions.find(
    (s) => s.default_price.id === to.query.product,
  );

  //Its a subscription type product, handle such toast
  if (to.query.checkout === 'product' && subscription) {
    if (status === 'success') {
      toast.success({
        message: 'Your new subscription is active.',
        duration: 5000,
      });
    }

    if (status === 'cancel') {
      toast.error({
        message:
          'Something went wrong while processing your subscription. Please try again.',
        duration: 5000,
      });
    }
  }

  const pack = paymentsStore.packs.find(
    (s) => s.default_price.id === to.query.product,
  );

  //Its a pack type product, handle such toast
  if (to.query.checkout === 'product' && pack) {
    if (status === 'success') {
      toast.success({
        message: 'Successful operation, your new balance is available.',
        duration: 5000,
      });
    }

    if (status === 'cancel') {
      toast.error({
        message:
          'Something went wrong while processing your payment. Please try again.',
        duration: 5000,
      });
    }
  }

  //Its subscription change event, handle such toast
  if (to.query.checkout === 'subscription') {
    if (status === 'success') {
      toast.success({
        message: 'Your subscription has been updated.',
        duration: 5000,
      });
    }

    if (status === 'cancel') {
      toast.error({
        message:
          'Something went wrong while processing your subscription change. Please try again.',
        duration: 5000,
      });
    }
  }

  //Its a subscription cancel event, handle such toast
  if (to.query.checkout == 'cancel') {
    if (status === 'success') {
      toast.success({
        title: 'Your monthly subscription was cancelled',
        message: 'Your status will remain until the period ends.',
        duration: 5000,
      });
    }

    if (status === 'cancel') {
      toast.error({
        message:
          'Something went wrong while trying to cancel your monthly subscription. Please try again.',
        duration: 5000,
      });
    }
  }

  //Its a subscription resume event, handle such toast
  if (to.query.checkout == 'resume') {
    if (status === 'success') {
      toast.success({
        title: 'Your monthly subscription was resumed',
        message: 'You can continue to enjoy your status benefits.',
        duration: 5000,
      });
    }

    if (status === 'cancel') {
      toast.error({
        message:
          'Something went wrong while trying to resume your monthly subscription. Please try again.',
        duration: 5000,
      });
    }
  }

  //Clear query after toast is sent.
  await router.replace({ query: undefined });
}
