<script setup lang="ts">
import useProfile from '@/composables/useProfile';
import ButtonComponent from '../ButtonComponent.vue';

const { hasUser } = useProfile();
</script>

<template>
  <router-link v-if="hasUser" :to="{ name: 'MyRoomsView' }">
    <ButtonComponent>My world</ButtonComponent>
  </router-link>
</template>

<style scoped></style>
