import axios from 'axios';
import {
  type DMChat,
  type ChatMessageRecord,
  type RoomChatMessageRecord,
} from './rapid-messaging.service.types';

const rapidMessagingApi = axios.create({
  baseURL: import.meta.env.VITE_API_RAPID_MESSAGING_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const rapidMessagingService = {
  async getDirectMessages() {
    return await rapidMessagingApi.get<ChatMessageRecord[]>(
      '/record/getMyRecords',
    );
  },
  async getMessagesByChatId(chatId: string) {
    return await rapidMessagingApi.get<ChatMessageRecord>(
      `/record/getMessagesRecord?chatId=${chatId}`,
    );
  },
  async getMessagesByRoomRefId(modelRefId: string) {
    return await rapidMessagingApi.get<RoomChatMessageRecord[]>(
      `/chat/messages?modelRefId=${modelRefId}`,
    );
  },
  async markRecordAsSeen(recordId: string) {
    return await rapidMessagingApi.put<ChatMessageRecord>(
      `/record/markRecordAsSeen?recordId=${recordId}`,
    );
  },
  async markRecordsAsSeen() {
    return await rapidMessagingApi.put<ChatMessageRecord>(
      `/record/markRecordsAsSeen`,
    );
  },
  async clearRecord(chatId: string) {
    return await rapidMessagingApi.put<ChatMessageRecord>(
      `/record/clear?chatId=${chatId}`,
    );
  },
  async existChat(receiverId: string) {
    return await rapidMessagingApi.get<DMChat>(
      `/dmChat/existChat?receiverId=${receiverId}`,
    );
  },
  async createChat(receiverId: string) {
    return await rapidMessagingApi.post<DMChat>(
      `/dmChat/create?receiverId=${receiverId}`,
    );
  },
};
