import { defineStore } from 'pinia';
import { useAuthStore } from '.';
import {
  ContractType,
  SequenceIndexer,
  type GetTransactionHistoryReturn,
  type GetTokenBalancesReturn,
  type TokenBalance,
  type GetEtherBalanceReturn,
  type TxnTransfer,
} from '@0xsequence/indexer';
import { USDT, BNB, MATIC } from '@/utils/wallet/tokensByDefault';
import { walletService } from '@/services/wallet.service';
import { handleAxiosError } from '@/utils/error';
import { type TokenToUSDT } from '@/services/wallet.service.types';
import useWallet from '@/composables/useWallet';
import { type MaySentTransactionResponse } from '@0xsequence/waas';

export interface TxInfo {
  transaction?: MaySentTransactionResponse;
  token?: TokenBalance;
}

export interface TransactionHistoryError {
  cause: string;
  code: number;
  error: string;
  msg: string;
  status: number;
}

export interface Wallet {
  chainEndpoint: string;
  chainName: string;
  tokenName: string;
  chainIcon: string;
  currentNFT?: TokenBalance;
  currentToken?: GetTokenBalancesReturn;
  tokenBalances?: GetTokenBalancesReturn;
  transactions?: GetTransactionHistoryReturn;
  mainToken?: GetEtherBalanceReturn;
  coinsValue: TokenToUSDT[];
  client?: SequenceIndexer;
  lastTxInfo: TxInfo;
  isIndexerLoading: boolean;
  walletAddress: string;
  currentTokenSelected: string;
  showWalletModal: boolean;
}

export const useWalletStore = defineStore('wallet', {
  state: (): Wallet => ({
    chainEndpoint: import.meta.env.VITE_CHAIN_ENDPOINT_BSC,
    chainName: 'BSC',
    tokenName: 'BNB',
    chainIcon: 'cryptocurrency-color:bnb',
    currentNFT: undefined,
    currentToken: undefined,
    tokenBalances: undefined,
    transactions: undefined,
    client: undefined,
    lastTxInfo: {
      transaction: undefined,
      token: undefined,
    },
    coinsValue: [],
    isIndexerLoading: false,
    walletAddress: '',
    currentTokenSelected: '',
    showWalletModal: false,
  }),
  actions: {
    async getIndexer(newEndpoint = false) {
      this.isIndexerLoading = true;
      try {
        const authStore = useAuthStore();
        await authStore.sequenceHash();

        this.walletAddress = authStore.walletAddress.wallet;

        if (!this.walletAddress) {
          console.error('Wallet address is not available.');
          return;
        }

        const { currentTokensSymbol } = useWallet();

        if (!this.client || newEndpoint) {
          this.client = new SequenceIndexer(
            `https://${this.chainEndpoint}-indexer.sequence.app`,
            import.meta.env.VITE_PROJECT_ACCESS_KEY,
          );
        }

        const [mainToken, tokenBalances] = await Promise.all([
          this.client.getEtherBalance({ accountAddress: this.walletAddress }),
          this.client.getTokenBalances({
            includeMetadata: true,
            accountAddress: this.walletAddress,
            includeCollectionTokens: true,
          }),
        ]);

        this.mainToken = mainToken;
        this.tokenBalances = tokenBalances;

        await this.getCoinsToUSDTConversion(currentTokensSymbol.value);

        try {
          this.transactions = await this.client.getTransactionHistory({
            filter: {
              accountAddress: this.walletAddress,
            },
            includeMetadata: true,
            page: { pageSize: 0 },
          });
        } catch (error) {
          if (
            typeof error === 'object' &&
            error !== null &&
            'status' in error
          ) {
            const typedError = error as TransactionHistoryError;
            if (typedError.status === 500) {
              this.transactions = { page: {}, transactions: [] };
            }
          }
        }
      } catch (error) {
        handleAxiosError(error);
      } finally {
        this.isIndexerLoading = false;
      }
    },
    handlerChain(chain: {
      name: string;
      endpoint: string;
      icon: string;
      token: string;
    }) {
      this.chainEndpoint = chain.endpoint;
      this.chainName = chain.name;
      this.chainIcon = chain.icon;
      this.tokenName = chain.token;
    },
    async getTokenById(tokenID: string, contractAddress: string) {
      const authStore = useAuthStore();

      if (!this.client) {
        this.client = new SequenceIndexer(
          `https://${this.chainEndpoint}-indexer.sequence.app`,
          import.meta.env.VITE_PROJECT_ACCESS_KEY,
        );
      }

      const currentHarcodedToken = [BNB, MATIC, USDT].find(
        (token) => token.contractAddress === contractAddress,
      );

      if (currentHarcodedToken) {
        return (this.currentToken = {
          page: {},
          balances: [currentHarcodedToken],
        });
      }

      this.currentToken = await this.client.getTokenBalances({
        includeMetadata: true,
        accountAddress: authStore.walletAddress.wallet,
        includeCollectionTokens: true,
        contractAddress,
        tokenID,
      });
    },
    async getCoinsToUSDTConversion(symbolArr: string[]) {
      try {
        if (!symbolArr.length) return;
        const symbols = JSON.stringify(symbolArr);
        const { data } = await walletService.getCoinsToUSDTConversion(symbols);
        this.coinsValue = data;
        return;
      } catch (error) {
        handleAxiosError(error, true);
      }
    },
    getAmountInUSD(
      token: TokenBalance | TxnTransfer,
      currentBalance: number,
      onlyBalance = false,
    ) {
      const symbolToken =
        token.contractType === ContractType.NATIVE
          ? this.tokenName
          : token.contractInfo?.symbol;
      const coinValue = this.coinsValue.find(
        (coin) => coin.symbol === `${symbolToken ? symbolToken : ''}USDT`,
      );
      if (
        (symbolToken === 'USDT' ||
          symbolToken === 'BNB-USD' ||
          symbolToken === 'USDC.e') &&
        onlyBalance
      )
        return currentBalance;
      if (
        symbolToken === 'USDT' ||
        symbolToken === 'BNB-USD' ||
        symbolToken === 'USDC.e'
      )
        return `${currentBalance.toFixed(4)} USD`;
      if (!coinValue?.price) return '';
      const calculatedValue = currentBalance * Number(coinValue.price);
      if (onlyBalance) return calculatedValue;
      return `${calculatedValue.toFixed(4)} USD`;
    },

    getMainTokenInUSD(currentMainToken: number) {
      const symbol =
        this.chainName === 'BSC'
          ? 'BNBUSDT'
          : this.chainName === 'Polygon'
            ? 'MATICUSDT'
            : null;

      if (!symbol) return '0.00';

      const coinValue = this.coinsValue.find((coin) => coin.symbol === symbol);
      if (!coinValue) return '0.00';

      return (currentMainToken * Number(coinValue.price)).toFixed(2);
    },
    async subscribeEvents() {
      const options = {
        onMessage: async () => {
          const [tokenBalances, transactions] = await Promise.all([
            this.client?.getTokenBalances({
              includeMetadata: true,
              accountAddress: this.walletAddress,
              includeCollectionTokens: true,
            }),
            this.client?.getTransactionHistory({
              filter: { accountAddress: this.walletAddress },
              includeMetadata: true,
            }),
          ]);

          this.tokenBalances = tokenBalances;
          this.transactions = transactions;
          return;
        },
        onError: (err: unknown) => {
          console.error('err', err);
        },
      };

      await this.client?.subscribeEvents(
        {
          filter: {
            accounts: [this.walletAddress],
            events: [
              'Transfer(address indexed from, address indexed to, uint256 value)', // ERC20
              'Transfer(address indexed from, address indexed to, uint256 indexed tokenId)', // ERC721
              'TransferBatch(address indexed operator, address indexed from, address indexed to, uint256[] ids, uint256[] values)', // ERC1155
            ],
          },
        },
        options,
      );
    },
    resetWallet() {
      this.chainEndpoint = import.meta.env.VITE_CHAIN_ENDPOINT_BSC;
      this.chainName = 'BSC';
      this.tokenName = 'BNB';
      this.chainIcon = 'cryptocurrency-color:bnb';
      this.currentNFT = undefined;
      this.currentToken = undefined;
      this.tokenBalances = undefined;
      this.transactions = undefined;
      this.client = undefined;
      this.lastTxInfo = {
        transaction: undefined,
        token: undefined,
      };
      this.coinsValue = [];
      this.isIndexerLoading = false;
      this.walletAddress = '';
      this.currentTokenSelected = '';
      this.showWalletModal = false;
      this.mainToken = undefined;
    },
  },
});
