export const tokenParser = (token?: string): string => {
  if (!token) return '';

  const tokenMap: Record<string, string> = {
    'BNB-USD': 'USDT', // Normalize name on BSC for USDT
    'USDT Token': 'USDT', // Normalize name on Polygon for USDT
    'USDC.e': 'USDC', // Normalize name on Polygon for USDC
  };

  return tokenMap[token] || token;
};
