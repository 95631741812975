<script lang="ts" setup>
import { useAuthStore } from '@/stores';
import GenericModal from '@/components/modals/GenericModal.vue';
import { computed } from 'vue';
import ButtonComponent from '@/components/common/ButtonComponent.vue';

const authStore = useAuthStore();

interface Props {
  modelValue: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const show = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

async function logOut() {
  show.value = false;
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('userAvatarImages_') || key === 'userAvatarImages') {
      localStorage.removeItem(key);
    }
  });
  await authStore.logout();
}
</script>

<template>
  <GenericModal v-model="show" size="xs" title="Log out">
    <template #default>
      <div class="flex w-full flex-col items-center text-center">
        <p class="my-3">You are about to log out, do you wish to proceed?</p>
        <div class="flex gap-4">
          <ButtonComponent width="sm" @click="show = false">
            Cancel
          </ButtonComponent>
          <ButtonComponent width="sm" variant="secondary" @click="logOut">
            Log out
          </ButtonComponent>
        </div>
      </div>
    </template>
  </GenericModal>
</template>
