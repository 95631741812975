<script setup lang="ts">
import LoginModal from '@/components/modals/auth/LoginModal.vue';
import { ref } from 'vue';
import ButtonComponent from '../ButtonComponent.vue';

const showLoginModal = ref(false);
</script>

<template>
  <ButtonComponent width="lg" @click="showLoginModal = true">
    Login
  </ButtonComponent>
  <LoginModal v-model="showLoginModal" />
</template>

<style scoped></style>
