import { toast } from '@/utils/toast';
import { computed, ref } from 'vue';

const useSocialShare = (shareUrl: string, shareText: string) => {
  const url = ref(shareUrl);
  const text = ref(shareText);

  const encodedUrl = computed(() => encodeURIComponent(url.value));
  const encodedText = computed(() => encodeURIComponent(text.value));

  function shareOnWhatsapp() {
    window.open(
      `https://web.whatsapp.com/send?text=${encodedText.value}%20${encodedUrl.value}`,
      '_blank',
    );
  }

  function shareOnTelegram() {
    window.open(
      `https://t.me/share/url?url=${encodedUrl.value}&text=${encodedText.value}`,
      '_blank',
    );
  }

  function shareOnTwitter() {
    window.open(
      `https://x.com/intent/post?url=${encodedUrl.value}&text=${encodedText.value}`,
      '_blank',
    );
  }

  function shareOnFacebook() {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl.value}&t=${encodedText.value}`,
      '_blank',
    );
  }

  function shareOnReddit() {
    window.open(
      `https://reddit.com/submit?url=${encodedUrl.value}&title=${encodedText.value}`,
      '_blank',
    );
  }

  async function copyToClipboard() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      await navigator.share({
        url: url.value,
        text: text.value,
      });
    } else {
      await navigator.clipboard.writeText(url.value);
      toast.success({
        message: 'Copied to clipboard',
      });
    }
  }

  //This function allows you to pass another function to execute if the user is not on mobile devices.
  async function navigatorShare(fallback: () => void) {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      await navigator.share({
        url: url.value,
        text: text.value,
      });
    } else {
      fallback();
    }
  }

  return {
    //data
    url,
    text,
    encodedUrl,
    encodedText,

    //methods
    shareOnWhatsapp,
    shareOnFacebook,
    shareOnReddit,
    shareOnTelegram,
    shareOnTwitter,
    copyToClipboard,
    navigatorShare,
  };
};

export default useSocialShare;
