<script setup lang="ts">
import { ref } from 'vue';

interface LoginInputProps {
  modelValue: string;
  error: boolean;
  title: string;
  name: string;
  errorMessage?: string;
}

const emits = defineEmits<{
  (e: 'update:modelValue', modelValue: string): string;
  (e: 'update', modelValue: string): string;
  (e: 'clearErrors'): void;
}>();

const { modelValue, error, title, errorMessage } =
  defineProps<LoginInputProps>();
const inputValue = ref(modelValue);

const handleInput = (event: Event) => {
  inputValue.value = (event.target as HTMLInputElement).value;
  emits('update:modelValue', inputValue.value);
  emits('update', inputValue.value);
  emits('clearErrors');
};
</script>

<template>
  <div class="relative">
    <label :for="name" class="mb-1 block text-sm">{{ title }}</label>
    <input
      class="h-9 w-full rounded-md border-2 p-4"
      type="email"
      :name="name"
      :class="{
        'border-2 bg-white text-black': !error,
        'border-2 border-red-500 bg-white text-red-700': error,
      }"
      placeholder="email@email.com"
      required
      maxlength="50"
      :value="inputValue"
      @input="handleInput"
    />
    <p v-if="error" class="absolute bottom-[-10] right-0 text-sm text-red-500">
      {{ errorMessage }}
    </p>
  </div>
</template>
