import { AxiosError } from 'axios';
import { toast } from '@/utils/toast';
interface AppError {
  status: string;
  message: string;
}

export function handleAxiosError(error: unknown, withToast = false): AppError {
  console.error(error);

  let appError: AppError;
  if (error instanceof AxiosError) {
    const axiosError = error as AxiosError<{ message: string }>;
    const status = axiosError.response?.status.toString() ?? 'UNKNOWN';
    const message = axiosError.response?.data.message ?? error.message;
    appError = {
      status: status,
      message: message,
    };
  } else if (error instanceof Error) {
    appError = {
      status: 'UNKNOWN',
      message: error.message || 'An unknown error occurred',
    };
  } else {
    appError = {
      status: 'UNKNOWN',
      message: 'An unknown error occurred',
    };
  }

  if (withToast) {
    toast.error({
      title: 'An error occurred.',
      message: appError.message,
    });
  }

  return appError;
}
