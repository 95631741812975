<script setup lang="ts">
import { authService } from '@/services';
import { onBeforeUnmount, ref } from 'vue';
import AuthInput from '@/components/auth/AuthInput.vue';
import ButtonComponent from '@/components/common/ButtonComponent.vue';
import { handleAxiosError } from '@/utils/error';

const newPassword = ref('');
const confirmNewPassword = ref('');
const passwordsError = ref(false);
const passwordsErrorMsg = ref('');
const passwordIsChanged = ref(false);
const codeSent = ref(false);
const emits = defineEmits<{
  update: [passwordIsChanged: boolean];
  back: [void];
}>();
const props = defineProps<{ email: string; code: string }>();

onBeforeUnmount(() => {
  codeSent.value = false;
});

const clearPasswordErrors = () => {
  passwordsError.value = false;
  passwordsErrorMsg.value = '';
};

const handlePassword = (password: string) => {
  newPassword.value = password;
};

const handleConfirmPassword = (password: string) => {
  confirmNewPassword.value = password;
};

const validateSamePasswords = () => {
  let error = false;

  if (newPassword.value !== confirmNewPassword.value) {
    passwordsError.value = true;
    passwordsErrorMsg.value = 'Las contraseñas deben coincidir';
    error = true;
  }

  const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
  if (!passwordRegex.test(newPassword.value)) {
    passwordsError.value = true;
    passwordsErrorMsg.value = 'Mínimo 8 caractéres, 1 mayúscula y 1 dígito';
    error = true;
  }

  return error;
};

const resetPassword = async () => {
  if (validateSamePasswords()) return;
  try {
    passwordIsChanged.value = true;
    codeSent.value = true;
    await authService.resetPassword(
      props.email,
      props.code,
      newPassword.value,
      confirmNewPassword.value,
    );
    codeSent.value = false;

    emits('update', true);
  } catch (error) {
    const { message } = handleAxiosError(error);

    passwordsError.value = true;
    passwordsErrorMsg.value = message;

    passwordIsChanged.value = false;
  }
};
</script>

<template>
  <main>
    <div class="flex flex-col">
      <form @submit.prevent="resetPassword">
        <div class="flex w-full flex-col">
          <div>Enter your new password</div>
          <div class="flex flex-col">
            <div class="relative mt-4 flex flex-col">
              <AuthInput
                v-model="newPassword"
                :error="passwordsError"
                :title="`New password`"
                :input-type="`password`"
                @clear-errors="clearPasswordErrors"
                @update="handlePassword"
              />
            </div>
            <div class="relative mt-4 flex flex-col">
              <AuthInput
                v-model="confirmNewPassword"
                :error="passwordsError"
                :title="`Confirm new password`"
                :input-type="`password`"
                :error-message="passwordsErrorMsg"
                @clear-errors="clearPasswordErrors"
                @update="handleConfirmPassword"
              />
            </div>
          </div>
          <div class="mt-10 flex flex-row items-center justify-center gap-5">
            <ButtonComponent
              width="sm"
              type="button"
              variant="ghost"
              @click="emits('back')"
            >
              Back
            </ButtonComponent>
            <ButtonComponent width="sm" :disabled="codeSent" type="submit">
              Confirm
            </ButtonComponent>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>
