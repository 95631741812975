import { computed } from 'vue';
import {
  useSocialStore,
  useShowcaseStore,
  useRoomInventoryStore,
} from '@/stores';
import {
  type UserRoomProductType,
  userRoomProductType,
  type SocialMedias,
  type SocialUser,
  type UserRoom,
} from '@/services';
import type { UserResource } from '@/services/room-inventory.service.types';
import { getFullName, getUiAvatar } from '@/utils';

const useProfile = () => {
  const socialStore = useSocialStore();
  const showcaseStore = useShowcaseStore();
  const roomInventoryStore = useRoomInventoryStore();
  const user = computed(() => {
    return socialStore._socialUser;
  });

  const hasUser = computed(() => !!socialStore._socialUser);
  const isLoadingUser = computed(() => socialStore.isLoadingUser);

  async function updateProfile(data: Partial<SocialUser>) {
    const { userImage: _, ...restData } = data;
    await socialStore.updateSocialUser(restData);
  }

  async function refreshMyUser() {
    await socialStore.refreshMyUser();
  }

  async function addPreferences(preferenceIds: string[]) {
    await socialStore.addPreferences(preferenceIds);
  }

  async function removePreferences(preferenceIds: string[]) {
    await socialStore.removePreferences(preferenceIds);
  }

  async function uploadUserImage(userImg: string) {
    await socialStore.uploadUserImage(userImg);
  }

  async function uploadSocialMedias(socialMedias: SocialMedias) {
    await socialStore.uploadSocialMedias(socialMedias);
  }

  async function getMyRooms() {
    if (showcaseStore.rooms) return;
    await showcaseStore.getMyRooms();
  }

  const rooms = computed<UserRoom[]>(() => showcaseStore.rooms ?? []);

  const hasHouse = computed(() =>
    rooms.value.some((room) =>
      Object.keys(room).some((key) => key === userRoomProductType.RoomHouse),
    ),
  );

  const hasIsland = computed(() =>
    rooms.value.some((room) =>
      Object.keys(room).some((key) => key === userRoomProductType.RoomIsland),
    ),
  );

  const hasHallOfFame = computed(() =>
    rooms.value.some((room) =>
      Object.keys(room).some(
        (key) => key === userRoomProductType.RoomHallOfFame,
      ),
    ),
  );

  const roomIsland = computed(
    () =>
      rooms.value.find((room) =>
        Object.keys(room).some((key) => key === userRoomProductType.RoomIsland),
      )?.['room:island'],
  );

  function createRoom(type: UserRoomProductType, templateId?: string) {
    return showcaseStore.createRoom(type, templateId);
  }

  const templates = computed<UserResource[]>(
    () => roomInventoryStore.templates ?? [],
  );

  return {
    //data
    user,
    hasUser,
    isLoadingUser,
    hasReadyPlayerMeAvatar: computed(
      () => !!socialStore._socialUser?.RPMAvatarId,
    ),
    userName: computed(() => user.value?.username),
    fullName: computed(() => (user.value ? getFullName(user.value) : '')),
    followerCount: computed(() => user.value?.followers?.length),
    followingCount: computed(() => user.value?.following?.length),
    avatar: computed(
      () =>
        user.value?.userImage ?? (user.value ? getUiAvatar(user.value) : ''),
    ),
    rooms,
    hasHouse,
    hasIsland,
    hasHallOfFame,
    templates,
    roomIsland,

    //methods
    updateProfile,
    refreshMyUser,
    addPreferences,
    removePreferences,
    uploadSocialMedias,
    uploadUserImage,
    getMyRooms,
    createRoom,
  };
};

export default useProfile;
