<script lang="ts" setup>
import {
  DMChat,
  ChatMessageRecord,
} from '@/services/rapid-messaging.service.types';
import { computed } from 'vue';
import { formatTimestamp } from '@/utils/format';
import { MinifiedSocialUser } from '@/services';
import { getUiAvatar } from '@/utils';

interface Props {
  item: ChatMessageRecord;
  user?: MinifiedSocialUser;
  selected?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  selected: false,
  user: undefined,
});

const fullName = computed(() =>
  props.user ? `${props.user.firstName} ${props.user.lastName}` : '',
);

const avatar = computed(
  () => props.user?.userImage ?? (props.user ? getUiAvatar(props.user) : ''),
);
</script>

<template>
  <RouterLink
    v-if="user"
    :to="{
      name: 'DirectMessagesView',
      query: { id: (item.chatId as DMChat)._id },
    }"
  >
    <div
      class="flex h-fit w-full items-center rounded-lg p-1.5 transition-colors sm:gap-1 sm:p-2"
      :class="{
        'bg-neutral-700': selected,
        'bg-backgrounds-darkestgray ': !selected,
      }"
    >
      <div class="flex h-full w-fit items-center sm:mx-1">
        <div class="avatar chat-image">
          <div
            class="aspect-square w-10 overflow-hidden rounded-full border-2 border-white"
          >
            <img :src="avatar" tabindex="0" class="align-top" />
          </div>
        </div>
      </div>
      <div class="flex w-px grow flex-col gap-1 px-2 py-0.5">
        <div class="flex w-full items-center justify-between">
          <p
            class="w-3/5 overflow-hidden text-ellipsis text-nowrap text-xs tracking-wide text-secondary brightness-200 sm:text-sm md:text-base"
          >
            <template
              v-if="
                !item.seen &&
                user._id === item.messages.at(-1)?.userId &&
                !selected
              "
            >
              <span
                class="mr-1 inline-block size-3 rounded-full bg-red-600"
              ></span>
            </template>
            {{ fullName }}
          </p>
          <p class="text-nowrap text-[10px] text-[#AEAEAE] sm:text-xs">
            {{ formatTimestamp(item.messages[0].createdAt.toString()) }}
          </p>
        </div>
        <div class="mb-2 flex w-11/12 items-center">
          <p
            class="mr-2 grow overflow-hidden text-ellipsis text-nowrap text-xs font-light text-secondary"
          >
            {{ item.messages[0].content }}
          </p>
        </div>
      </div>
    </div>
  </RouterLink>
</template>
