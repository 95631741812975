import { defineStore } from 'pinia';
import { useSocialStore } from './social';
import { queryClient } from '@/plugins/vue-query';
import { type Resource } from '@/services/room-inventory.service.types';
import { roomInventoryService } from '@/services/room-inventory.service';
import { handleAxiosError } from '@/utils/error';
import { usePaymentsStore } from './payments';
import { useRoomInventoryStore } from './room-inventory';

interface ResourcesState {
  checkout: {
    product?: Resource;
    quantity?: number;
    purchaseToken?: string;
    show: boolean;
    promise?: Promise<{ product: Resource; quantity: number }>;
    resolve?: (value: { product: Resource; quantity: number }) => void;
    reject?: (value: { message: string }) => void;
  };
}

export const useResourcesStore = defineStore('resources', {
  state: (): ResourcesState => ({
    checkout: {
      product: undefined,
      quantity: undefined,
      purchaseToken: undefined,
      show: false,
      promise: undefined,
      resolve: undefined,
      reject: undefined,
    },
  }),
  actions: {
    async checkoutInit(resource: Resource, quantity = 1) {
      this.checkout.product = resource;
      this.checkout.quantity = quantity;
      this.checkout.show = true;

      this.checkout.promise = new Promise<{
        product: Resource;
        quantity: number;
      }>((resolve, reject) => {
        this.checkout.resolve = resolve;
        this.checkout.reject = reject;
      });

      return this.checkout.promise;
    },
    async checkoutConfirm() {
      try {
        if (!this.checkout.product?.id || !this.checkout.quantity) return;

        const { data } = await roomInventoryService.getPurchaseToken(
          this.checkout.product.id,
          this.checkout.quantity,
        );

        this.checkout.purchaseToken = data.token;

        if (!this.checkout.purchaseToken) return;

        await roomInventoryService.confirmPurchase(
          this.checkout.product.id,
          this.checkout.quantity,
          this.checkout.purchaseToken,
        );

        return this.checkout.promise;
      } catch (error) {
        if (!this.checkout.reject) return;
        const { message } = handleAxiosError(error);

        this.checkout.reject({ message: message });
      }
    },
    async checkoutComplete() {
      const paymentsStore = usePaymentsStore();
      if (!this.checkout.resolve || !this.checkout.product) return;
      const socialStore = useSocialStore();
      const roomInventoryStore = useRoomInventoryStore();

      queryClient.removeQueries({
        queryKey: ['payments-coins', socialStore._socialUser?._id],
      });

      await paymentsStore.getCoins();

      await roomInventoryStore.getMyTemplates();

      this.checkout.resolve({
        product: this.checkout.product,
        quantity: this.checkout.quantity ?? 1,
      });

      this.checkout = {
        product: undefined,
        quantity: undefined,
        purchaseToken: undefined,
        show: false,
        promise: undefined,
        resolve: undefined,
        reject: undefined,
      };
    },
    checkoutCancel() {
      if (!this.checkout.reject) return;

      this.checkout.reject({ message: 'The operation was cancelled.' });

      this.checkout = {
        product: undefined,
        quantity: undefined,
        purchaseToken: undefined,
        show: false,
        promise: undefined,
        resolve: undefined,
        reject: undefined,
      };
    },
  },
});
