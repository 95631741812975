<script lang="ts" setup>
import GenericModal from '@/components/modals/GenericModal.vue';
import { computed, ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import ButtonComponent from '@/components/common/ButtonComponent.vue';
import TextField from '@/components/form/TextField.vue';

const authStore = useAuthStore();
const authCode = ref('');

interface Props {
  modelValue: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
  'on:close': [];
}>();

const show = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

function onOkHandler() {
  authStore.sendTokenLogin(authCode.value);
  authStore.showAuthCodeModal = false;
  authCode.value = '';
}

function onCancelHandler() {
  authStore.showAuthCodeModal = false;
  authCode.value = '';
}
</script>

<template>
  <GenericModal
    v-model="show"
    size="sm"
    title="Enter your verification code"
    :close-to-click-outside="false"
  >
    <template #default>
      <div class="flex w-full flex-col">
        <p class="my-3 text-pretty text-sm sm:text-base">
          Please enter the code provided in your email.
        </p>
        <TextField
          id="auth-code"
          v-model="authCode"
          class="w-full"
          placeholder="123456"
          name="authCode"
          type="text"
          @keydown.enter="onOkHandler"
        />
      </div>
    </template>
    <template #footer>
      <div
        class="-mr-4 flex scale-90 justify-end gap-2 sm:mr-0 sm:scale-100 sm:gap-4"
      >
        <ButtonComponent
          width="xs"
          variant="secondary"
          @click="onCancelHandler"
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          width="xs"
          :is-loading="authStore.isConnectingWallet"
          @click="onOkHandler"
        >
          Submit
        </ButtonComponent>
      </div>
    </template>
  </GenericModal>
</template>
